import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td
} from "../../../components/elements/Table";
import { Box } from "../../../components/elements";
import _ from "lodash";
import { Avatar, Flex, Grid } from "@chakra-ui/react";
import { formatDateHelper } from "../../../utils/formatDate";

const getURL = (name) => {
  return `https://api.octoelysium.com/crm/storage/images/${name}`;
};

const thead = [
  "ID",
  "Title",
  "Minimun Deposit",
  "Leverage",
  "Group",
  "Demo Group",
  "Hide From Client",
  ""
];
// {req: {}, status}
const DepositTable = ({ requests, onChangeStatus }) => {
  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {requests?.length ? (
            <>
              {_.map(requests, (_request) => {
                return (
                  <Tr key={_request?.id}>
                    <Td>#{_request?.id}</Td>
                    <Td>{_request?.title ?? "-"}</Td>
                    <Td>{_request?.Minimun_Deposit ?? "-"}</Td>
                    <Td>{_request?.Leverage ?? "-"}</Td>
                    <Td>{_request?._Group ?? "-"}</Td>
                    <Td>{_request?.demo_group ?? "-"}</Td>
                    <Td>{_request?.hide_on_fe ? "Yes" : "No"}</Td>
                    <Td>
                      <div style={{ width: "100%", textAlign: "right" }}>
                        <button
                          onClick={() => {
                            onChangeStatus(_request);
                          }}
                          className="mc-btn primary"
                        >
                          Edit
                        </button>
                        &nbsp;
                        <button
                          onClick={() => {
                            onChangeStatus(_request);
                          }}
                          className="mc-btn red"
                        >
                          Delete
                        </button>
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan={thead?.length ?? 1}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  No Data found
                </div>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DepositTable;
