import React from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Button, Item } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import UsersTable from "../../components/tables/UsersTable";
import PageLayout from "../../layouts/PageLayout";
import jsonData from "../../data/master/myTransactions.json";
import TransactionsTable from "../../components/tables/TransactionsTable";
import useSession from "../../hooks/useSession";
import { Box, Divider, Skeleton, Flex, Text, Avatar } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  getMyAccounts,
  getMyTransactions,
  getPaymentMethodsService
} from "../../services/client";
import _ from "lodash";

export default function MyDownloads() {
  return (
    <PageLayout
      title="Downloads"
      breadcrumb={[
        { title: "Home", path: "/dashboard" },
        {
          title: "Downloads"
        }
      ]}
    >
      <TransactionsContainer />
    </PageLayout>
  );
}

const TransactionsContainer = () => {
  return (
    <Row>
      <Col md={4}>
        <CardBox
          title="Desktop"
          desp="Download link for Forever Capital MT5 for windows desktop"
          downloadLink="https://download.mql5.com/cdn/web/forever.capital.ltd/mt5/forevercapital5setup.exe"
          imageURL="/images/download_for_desktop.png"
        />
      </Col>

      <Col md={4}>
        <CardBox
          title=" IOS Mobile"
          desp="Download link for Forever Capital MT5 for IOS Mobile"
          downloadLink="https://download.mql5.com/cdn/mobile/mt5/ios"
          imageURL="/images/download_for_apple.png"
        />
      </Col>

      <Col md={4}>
        <CardBox
          title="Android Mobile"
          desp="Download link for Forever Capital MT5 for Android Mobile"
          downloadLink="https://download.mql5.com/cdn/mobile/mt5/android"
          imageURL="/images/download_for_android.png"
        />
      </Col>
    </Row>
  );
};

const CardBox = ({ title, desp, downloadLink, imageURL }) => {
  return (
    <Box p={4} border="1px solid" borderColor="gray.200">
      <Flex align="center">
        <Box flex={1}>
          <h5>{title}</h5>
          <Text fontSize="xs">{desp}</Text>
        </Box>

        <Box ml={4}>
          <Avatar src={imageURL} />
        </Box>
      </Flex>

      <Divider />
      <Box>
        <Box ml="auto" textAlign="right">
          <a target="_blank" href={downloadLink} className="mc-btn primary sm">
            Download
          </a>
        </Box>
      </Box>
    </Box>
  );
};
