import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
  Text
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import { WithoutAuth } from "../../hoc/WithoutAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSession from "../../hooks/useSession";
import { Link, useNavigate } from "react-router-dom";
import { useShowToast } from "../../hooks/useShowToast";
import { f2aLoginService, verifyOTPService } from "../../services/auth.service";
import { STORAGE } from "../../config/storage";
import { isEmpty } from "lodash";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { background } from "@chakra-ui/react";
import { setToken } from "../../utils/token";

const loginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required")
});

export default function Login() {
  const [user, isLoading, setUser] = useSession();
  const [otp, setOtp] = useState("");
  const [verifiedOTP, setVerifiedOTP] = useState(false);
  const navigate = useNavigate();
  const showToast = useShowToast();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: loginSchema,
    onSubmit: (values, actions) => {
      handleLogin(values, actions);
    }
  });

  useEffect(() => {
    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      formik.handleSubmit(); // Call Formik's handleSubmit
    }
  };

  const handleLogin = async (values, actions) => {
    actions.setSubmitting(true);

    if (verifiedOTP) {
      if (!otp) {
        showToast("Error", "Please enter the OTP!", "error");
        actions.setSubmitting(false);
        return;
      }
      try {
        const result = await verifyOTPService(values?.email, otp);

        if (result?.data?.success) {
          const user = {
            ...result?.data?.data[0]?.user,
            token: result?.data?.data[0]?.token ?? null
          };
          if (!isEmpty(user) && user?.type === "ADMIN") {
            showToast("Successfull", "OTP verified successfully");
            //localStorage.setItem(STORAGE.USER, JSON.stringify(user));
            setToken(user?.token);
            setUser(user);
            window.location.href =
              user?.type === "ADMIN" ? "/admin/dashboard" : "/dashboard";
            actions.setSubmitting(false);
            setVerifiedOTP(false);
            return;
          }
        } else {
          showToast("Error", "Bad credentials", "error");
          actions.setSubmitting(false);
          return;
        }
      } catch (error) {}
    } else {
      try {
        const token = await executeRecaptcha("login");

        console.log(token);

        const result = await f2aLoginService(values?.email, values?.password);

        if (result?.data?.success) {
          showToast("Successfull", result?.data?.message);
          actions.setSubmitting(false);
          setVerifiedOTP(true);
          return;
        } else {
          showToast("Error", "Bad credentials", "error");
          actions.setSubmitting(false);
          return;
        }
      } catch (error) {}
    }

    showToast("Error", "Bad credentials", "error");
    actions.setSubmitting(false);
  };

  const resetFormik = () => {
    formik?.resetForm();
    setVerifiedOTP(false);
  };

  return (
    <WithoutAuth>
      <Box
        className="mc-auth"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Image
          src={data?.pattern.src}
          alt={data?.pattern.alt}
          className="mc-auth-pattern"
        />
        <Box className="mc-auth-group p-4" style={{ minWidth: "360px" }}>
          <Logo
            src={data?.logo.src}
            alt={data?.logo.alt}
            href={data?.logo.path}
            className="mc-auth-logo"
          />
          <Heading as="h4" className="mc-auth-title">
            {data?.title}
          </Heading>

          <form onSubmit={formik.handleSubmit}>
            {verifiedOTP ? (
              <>
                <Text as="span">
                  An otp has been sent to your, email address -{" "}
                  <b>{formik?.values?.email}</b>.
                  <br />
                  Please enter it to login.
                </Text>
                <OtpInput
                  shouldAutoFocus
                  containerStyle={{
                    justifyContent: "center",
                    margin: "10px 0"
                  }}
                  inputStyle={{
                    border: "1px solid gray",
                    margin: "2px",
                    width: "20px"
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </>
            ) : (
              <>
                {data?.input.map((item, index) => (
                  <IconField
                    key={index}
                    icon={item.icon}
                    type={item.type}
                    option={item.option}
                    classes={item.fieldSize}
                    placeholder={item.placeholder}
                    passwordVisible={item.passwordVisible}
                    name={item?.name}
                    value={formik?.values[item?.name]}
                    onBlur={formik?.handleBlur}
                    onChange={formik?.handleChange}
                    errorMessage={
                      formik?.errors[item?.name] && formik?.touched[item?.name]
                        ? formik?.errors[item?.name]
                        : false
                    }
                  />
                ))}

                <div
                  style={{
                    textDecoration: "underline",
                    fontSize: "12px",
                    textAlign: "right"
                  }}
                >
                  <Link to="/forgot-password"> Forgot Password?</Link>
                </div>

                <br />
              </>
            )}

            <button
              type="button"
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              disabled={formik?.isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            >
              <div>
                {verifiedOTP && formik.isSubmitting
                  ? "Validating"
                  : verifiedOTP
                  ? data?.otp.text
                  : formik?.isSubmitting
                  ? "Submitting"
                  : data?.button.text}
              </div>
            </button>
          </form>

          {/* <Anchor className="mc-auth-forgot" href={data?.forgot.path}>
              {data?.forgot.text}
            </Anchor> */}
          {/* <Box className="mc-auth-divide"><Text as="span">{ data?.divide.text }</Text></Box> */}
          <Box className="mc-auth-connect">
            {data?.connect.map((item, index) => (
              <Anchor key={index} href={item.path} className={item.classes}>
                <i className={item.icon}></i>
                <span>{item.text}</span>
              </Anchor>
            ))}
          </Box>

          <Box className="mc-auth-navigate">
            {verifiedOTP ? (
              <>
                <Text as="span">
                  <Anchor style={{ color: "#7328e7" }} onClick={resetFormik}>
                    <b>Change</b>
                  </Anchor>{" "}
                  email address.
                </Text>
              </>
            ) : (
              <>
                <Text as="span">{data?.navigate.title}</Text>
                <Anchor href={data?.navigate.path}>
                  {data?.navigate.text}
                </Anchor>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </WithoutAuth>
  );
}
